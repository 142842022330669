import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function MtCookAorakiQueenstownReturn(props) {
  return (
    <>
      <SEO title="Mt Cook / Aoraki Day Tour - Queenstown Return - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-sunset.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-mountain-divide.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/mount-cook-mount-sefton.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="AWQMQ" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Mt Cook - Queenstown Return</h1>
                <h3>Follow the Southern Alps to magnificent Aoraki</h3>
                <div class="highlights">
                  <ul>
                    <li>Kawarau River Gorge, Lake Dunstan, Mackenzie Basin</li>
                    <li>Aoraki / Mt Cook National Park</li>
                    <li>Mt Cook Village</li>
                    <li>Optional Scenic Flight*</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <p>
                      Leaving <strong>Queenstown</strong> we travel north
                      through the historic gold mining region of the rugged{" "}
                      <strong>Kawarau River Gorge</strong> and past the famous
                      fruit growing region of <strong>Cromwell</strong>.
                      Skirting the shores of Lake Dunstan, we cross the{" "}
                      <strong>Lindis Pass</strong> and arrive at{" "}
                      <strong>Omarama</strong> for morning tea (own expense).
                    </p>
                    <p>
                      Continue through the rugged high country landscape of the{" "}
                      <strong>Mackenzie Basin</strong> to arrive at{" "}
                      <strong>Aoraki / Mt Cook National Park</strong>, home to
                      Australasia's highest mountain at 3724 metres. Enjoy lunch
                      (own expense) in Aoraki / Mt Cook Village and take in the
                      majestic alpine scenery.
                    </p>
                    <p>
                      While you are at Aoraki / Mt Cook there is the chance to
                      enjoy a scenic flight with The Helicopter Line or Mt Cook
                      Ski Planes over this spectacular alpine region (own
                      expense, this flight can be arranged with your
                      driver/guide).
                    </p>
                    <p>
                      In the afternoon, your tour departs Mt Cook Village and
                      returns to Queenstown.
                    </p>
                    <p>
                      *Optional return flight is at your own expense and can be
                      arranged with your driver.
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>
                {/* ... */}
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/mtcook_zqn.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Mt Cook - Queenstown to Christchurch</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$99.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki-queenstown-christchurch">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/mtcook.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Mount Cook Day Tour</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$129.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/mt-cook-tours/mt-cook-aoraki">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                {/* ... */}
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default MtCookAorakiQueenstownReturn
